<template>
  <sdCards :title="$t('views.userProfile.changePassword')" >
    <a-row type="flex" justify="center">
      <a-col :lg="12" :sm="20" :xs="24">
        <app-form
          :fields="fields"
          :loading="loading"
          :submitButton="submitButton"
          :resetForm="resetForm"
          @submitted="handleSubmit"
          @update:resetForm="resetForm = false"
        />
      </a-col>
    </a-row>
  </sdCards>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {required} from '@/helpers/validationRules';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const {t} = useI18n();
    const fields = {
      currentPassword: {
        type: 'password',
        class: 'mb-15',
        label: 'models.user.attributes.currentPassword',
        fieldValue: '',
        span: 24,
        rules: [{...required, message: t('messages.error.required')}]
      },
      password: {
        type: 'password',
        class: 'mb-15',
        label: 'models.user.attributes.newPassword',
        validateDependent: 'passwordConfirmation',
        fieldValue: '',
        span: 24,
        rules: [{
          ...required, message: t('messages.error.required')
        },
        {
          min: 8, message: t('messages.error.minLength', {min: 8}), trigger: ['blur', 'change'], type: 'string'
        }]
      },
      passwordConfirmation: {
        type: 'password',
        class: 'mb-15',
        customValidation: true,
        validateAgainst: 'password',
        label: 'models.user.attributes.newPasswordConfirmation',
        fieldValue: '',
        span: 24,
        rules: [{...required, message: t('messages.error.required')}]
      }
    };

    const submitButton = {
      label: 'views.setPassword.title',
      class: 'btn-signin',
      size: 'large',
      type: 'primary',
      block: false
    };

    const {dispatch} = useStore();
    const loading = ref(false);
    const resetForm = ref(false);
    const handleSubmit = (data) => {
      loading.value= true;
      dispatch('updateAccount', data).then((status) => {
        loading.value = false;
        if (status === 200) resetForm.value = true;
      });
    };

    return {
      fields,
      submitButton,
      loading,
      resetForm,
      handleSubmit
    };
  }
});
</script>
